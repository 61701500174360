import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Link, Route, Switch, useHistory, useParams, useRouteMatch,} from "react-router-dom";
import {SpinnerComponent} from "react-element-spinner";
import Calendar from "./Calendar";

function loadCampaign(campaign_name, setLoading, setCampaign, setError) {
    return () => {
        let abortController = new AbortController();
        let signal = abortController.signal;

        fetch(`/api/campaigns/${campaign_name}`, {signal})
            .then(response => {
                setLoading(false);
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                setCampaign(json);
            })
            .catch(err => {
                setError('Failed to load campaign.');
            })
        return () => abortController.abort();
    }
}

const join = (base, path) => {
    return base.charAt(base.length - 1) === '/'
        ? base + path
        : base + '/' + path
}

let SelectPlayer = () => {
    let match = useRouteMatch();
    let history = useHistory();
    let {campaign: campaign_name, year, month} = useParams();

    let [isLoading, setLoading] = useState(true);
    let [campaign, setCampaign] = useState();
    let [error, setError] = useState();
    useEffect(loadCampaign(campaign_name, setLoading, setCampaign, setError), []);

    // TODO This and last month's players.
    let players = campaign && Object.keys(((campaign['preferences'][year] || {})[month]) || []);

    function newPlayer(e) {
        e.preventDefault();
        history.push(join(match.url, e.target.name.value));
    }

    return <div>
        <div>
            <SpinnerComponent loading={isLoading} position="centered"/>
            {players && players.map(player =>
                <div key={player}>
                    <Link to={join(match.url, player)}>{player}</Link>
                </div>)
            }
        </div>
        <form onSubmit={newPlayer}>
            <input autoFocus type="text" placeholder="New player" name="name"/>
            <input type="submit" value="Go"/>
        </form>
        {error && <div>{error}</div>}
    </div>;
}

function addDays(now, n) {
    let then = new Date(now);
    then.setDate(then.getDate() + n);
    return then;
}

let CampaignMonth = ({location}) => {
    let {campaign: campaign_name} = useParams();
    let match = useRouteMatch();
    let [isLoading, setLoading] = useState(true);
    let [campaign, setCampaign] = useState();
    let [hoveredDay, setHoveredDay] = useState();
    let [invertDay, setInvertDay] = useState(false);
    let [error, setError] = useState();
    let noSigns = true;

    let today = new Date();

    let searchParams = new URLSearchParams(location.search);
    let today1 = searchParams.get('today');
    if (today1) {
        today = new Date(Date.parse(today1));
    }

    let year = today.getFullYear();
    let month = today.getMonth() + 1;

    useEffect(loadCampaign(campaign_name, setLoading, setCampaign, setError), []);

    let numColumns = 30;
    let days = [...new Array(numColumns).keys()];
    let yes = [...new Array(numColumns).fill(0)];
    let maybe = [...new Array(numColumns).fill(0)];
    let no = [...new Array(numColumns).fill(0)];
    let signed = [...new Array(numColumns)];
    // today.setDate(1);

    let players = {};
    if (campaign) {

        let storedYear = campaign['preferences'][year] || {}
        let storedMonth = storedYear[month] || {}

        Object.entries(storedMonth).forEach(([player, preferences]) => {
            players[player] = preferences;
            noSigns = false;
        });

        let nextMonth = today.getMonth() === 11
            ? new Date(today.getFullYear() + 1, 0, 1)
            : new Date(today.getFullYear(), today.getMonth() + 1, 1);
        let storedYear2 = campaign['preferences'][nextMonth.getFullYear()] || {}
        let storedMonth2 = storedYear2[nextMonth.getMonth() + 1] || {}

        Object.keys(players).forEach(player => {
            let nextPreferences = storedMonth2[player] || [...new Array(10)];
            players[player] = players[player].concat(nextPreferences);
        });

        days.forEach(day => {
            for (const [player, preferences] of Object.entries(players)) {
                if (!signed[day]) {
                    signed[day] = {}
                }
                let offset = day + today.getDate() - 1;
                switch (preferences[offset]) {
                    case 0:
                        no[day]++;
                        if (!(player in signed[day])) {
                            signed[day][player] = 0;
                        }
                        break;
                    case 1:
                        yes[day]++;
                        if (!(player in signed[day]) || signed[day][player] < 1) {
                            signed[day][player] = 1;
                        } else {
                        }
                        break;
                    case 2:
                        maybe[day]++;
                        if (!(player in signed[day]) || signed[day][player] < 2) {
                            signed[day][player] = 2;
                        } else {
                        }
                        break;
                    default:
                }
            }
        })
    }

    let editSigns = <Link to={join(match.url, `${year}/${month < 10 ? '0' : ''}${month}/edit`)}>
        <button>edit</button>
    </Link>;

    // TODO: y-gradering eller motsvarande

    function foo(player, hoveredDay) {
        if (typeof hoveredDay === "undefined") {
            return "";
        }
        if (typeof signed[hoveredDay] === "undefined") {
            return "";
        }

        switch (signed[hoveredDay][player]) {
            case 0:
                return "no";
            case 1:
                return "yes";
            case 2:
                return "maybe"
            default:
                return ""
        }
    }

    let history = useHistory();

    let weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    function getDisplayDay(day) {
        let thatDay = addDays(today, day);
        if (day === 0) {
            return 'Today'
        }
        if (invertDay) {
            if (hoveredDay === day) {
                return thatDay.getDate();
            }
            return weekdays[thatDay.getDay()];
        }
        if (hoveredDay === day) {
            return weekdays[thatDay.getDay()];
        }
        return thatDay.getDate();
    }

    return <>
        <div>
            <SpinnerComponent loading={isLoading} position="centered"/>
            {error && <div>{error}</div>}
            <div className={"content"}>
                {campaign && <h1>{campaign['meta']['description']}</h1>}
                {campaign && noSigns
                    ? <div>There are no signs yet, {editSigns} to get started!</div>
                    : <div className={"mirror-chart"}>
                        {days.map(day =>
                            <div key={`upper-day-${day}`}
                                 className={`${hoveredDay === day && "highlighted"} upper day day-${day} `}
                                 onMouseOver={() => setHoveredDay(day)}
                                 onMouseOut={() => setHoveredDay(null)}
                            >
                                {new Array(maybe[day]).fill(<div className={"noisy maybe"}>&nbsp;</div>)}
                                {new Array(yes[day]).fill(<div className={"noisy yes"}>&nbsp;</div>)}
                                {<div
                                    onMouseOver={() => setHoveredDay(day)}
                                    onMouseOut={() => setHoveredDay(null)}
                                    onClick={() => setInvertDay(!invertDay)}
                                    className={`${hoveredDay === day && "highlighted"} day ${(addDays(today, day).getDay() === 6 || addDays(today, day).getDay() === 0) && "helg"}`}>{getDisplayDay(day)}</div>}
                            </div>)}
                        {days.map(day =>
                            <div
                                key={`lower-day-${day}`}
                                onMouseOver={() => setHoveredDay(day)}
                                onMouseOut={() => setHoveredDay(null)}
                                className={`${hoveredDay === day && "highlighted"} lower day day-${day} `}>
                                {new Array(no[day]).fill(<div className={"noisy no"}>&nbsp;</div>)}
                            </div>)}
                    </div>
                }
            </div>
            {/*<span>Signs for {campaign_name}, {today.toDateString()}. </span>*/}
            {!isLoading && !noSigns && <div className={"align-right"}>
                <div className={"toolbar"}>{editSigns}</div>
                <div className={"hovers toolbar"}>
                    {players && Object.keys(players).map(player => <button
                        onClick={() => history.push(`/campaigns/${campaign_name}/${year}/${month}/edit/${player}`)}
                        className={"person " + foo(player, hoveredDay)}>{player}</button>)}
                </div>
                {/*{hoveredDay !== null && <div className={"hovers"}>*/}
                {/*    /!*<span className={"yes"}>{signedWhat(hoveredDay, 1)}</span>*!/*/}
                {/*    /!*<span className={"maybe"}>{signedWhat(hoveredDay, 2,)}</span>*!/*/}
                {/*    /!*<span className={"no"}>{signedWhat(hoveredDay, 0)}</span>*!/*/}
                {/*</div>*/}
                {/*}*/}
            </div>}
        </div>
    </>;
}

let CalendarRenderer = () => {
    let {campaign: campaign_id, year, month, player} = useParams();
    year = parseInt(year);
    month = parseInt(month);
    let calendarKey = `${campaign_id}-${campaign_id}-${year}-${month}-${player}`

    return <Calendar key={calendarKey}/>;
}

export default () => {
    let match = useRouteMatch();
    return <Router>
        <Switch>
            <Route path={match.path + "/:year/:month/edit/:player"} component={CalendarRenderer}/>
            <Route path={match.path + "/:year/:month/edit"} component={SelectPlayer}/>
            <Route path={match.path + "/"} component={CampaignMonth}/>
            {/*<Route exact path={match.path + "/"} component={CurrentMonth}/>*/}
            <Route>Campaign not found.</Route>
        </Switch>
    </Router>;
}
